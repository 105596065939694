/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

import StatusBadge from "../../components/OfferingStatusBadge";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import moment from "moment";
import {
  getIssuerById,
  getOfferingByIssuerId,
  isAdmin,
  isIssuerOwner,
  stockTypeName,
} from "../../helpers";

const IssuerOfferings = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: me } = useCurrentUser();
  const { id } = useParams();
  const issuer = useSelector((state) => state.issuer);

  const [offerings, setOfferings] = useState([]);
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    showSpinner(true);
    getIssuerById(id).then((issuer) =>
      dispatch({ type: "issuer", payload: issuer })
    );
    getOfferingByIssuerId(id).then((offerings) => {
      setOfferings(offerings);
      showSpinner(false);
    });
  }, []);

  const goToOffering = async (offering) => {
    dispatch({ type: "offering", payload: offering });
    history.push(`/offering/${offering.id}/overview`);
  };

  return (
    <>
      <Header name="Offerings" title={issuer?.name}>
        {isAdmin(me) && (
          <Link to={`/issuer/${id}/edit`} className="btn btn-light me-2">
            Edit
          </Link>
        )}
        {(isAdmin(me) || isIssuerOwner(me, issuer?.id)) && (
          <Link to="/offering/create" className="btn btn-eq-primary">
            New Offering
          </Link>
        )}
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={spinner}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th className="">Offering</th>
                    <th className="">Type</th>
                    <th className="">Launch Date</th>
                    <th className="">Amount</th>
                    <th className="">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {offerings.map((offering, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => goToOffering(offering)}
                        style={{ cursor: "pointer" }}
                      >
                        <td className="align-baseline">
                          {offering.name}
                          <div className="small text-muted">
                            {offering.issuer?.name}
                          </div>
                        </td>
                        <td className=" align-baseline">
                          {stockTypeName(offering.stock_type)}
                        </td>
                        <td className=" align-baseline">
                          {offering.launch_date &&
                            moment(offering.launch_date).format("ll")}
                        </td>
                        <td className=" align-baseline">
                          <NumberFormat
                            value={offering.total_amount}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={0}
                            prefix="$"
                          />
                        </td>
                        <td className=" align-baseline">
                          <StatusBadge status={offering.status} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>
    </>
  );
};

export default IssuerOfferings;
