import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

const MissingPage = () => {
  const { push } = useHistory();
  const toast = useToast();
  useEffect(() => {
    toast({
      status: "error",
      description: "That page does not exist",
    });
    push("/offering");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default MissingPage;
