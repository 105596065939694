import clsx from "clsx";
import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useParams } from "react-router-dom";

import { identify as identifyFullStory } from "@equidefi/portals/helpers/fullstory";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import Spinner from "../components/spinner";
import { getIssuerById, isAdmin, isAnyIssuerOwner } from "../helpers";
import { useIssuer } from "../hooks/useIssuers";
import { useOffering } from "../hooks/useOfferings";
import offeringNav from "./../data/nav_offering.json";

const OfferingMenu = () => {
  const { id } = useParams();
  const { data: offering } = useOffering(id);
  const { data: user } = useCurrentUser();

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <hr className="navbar-divider my-3" />
      <h3 className="navbar-heading fw-bold text-dark fs-lg text-capitalize">
        {offering.name}
      </h3>
      <ul className="navbar-nav">
        {offeringNav.map((item, index) => {
          if (
            item.roles &&
            !isAdmin(user) &&
            !item.roles.includes(user?.roles?.offering[offering.id])
          )
            return null;
          let navClass = window.location.pathname.includes(item.link)
            ? "active"
            : "";
          return (
            <li key={index} className="nav-item">
              <Link
                to={`/offering/${id}/${item.link}`}
                className={`nav-link ${navClass}`}
              >
                <i className={`fe fe-${item.icon}`} /> {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const IssuerMenu = () => {
  const { data: user } = useCurrentUser();
  const { id } = useParams();
  const { data: issuer } = useIssuer(id);
  if (!issuer) {
    return <Spinner show />;
  }

  return (
    <>
      <hr className="navbar-divider my-3" />
      <h6 className="navbar-heading">{issuer.name}</h6>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to={`/issuer/${issuer.id}/offerings`}
            className={clsx("nav-link", {
              active: window.location.pathname.includes("offerings"),
            })}
          >
            <i className="fe fe-clipboard" /> Offerings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/issuer/${issuer.id}/users`}
            className={clsx("nav-link", {
              active: window.location.pathname.includes("users"),
            })}
          >
            <i className="fe fe-users" /> Users
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to={`/issuer/${issuer.id}/invitations`}
            className={clsx("nav-link", {
              active: window.location.pathname.includes("invitations"),
            })}
          >
            <i className="fe fe-users" /> Invitations
          </Link>
        </li>
        <li className="nav-item">
          {isAdmin(user) && (
            <Link
              to={`/issuer/${issuer.id}/roles`}
              className={clsx("nav-link", {
                active: window.location.pathname.includes("roles"),
              })}
            >
              <i className="fe fe-shield" /> Roles
            </Link>
          )}
        </li>
      </ul>
    </>
  );
};

const EIGHT_HOURS = 1_000 * 60 * 60 * 8;

const Menu = (props) => {
  const dispatch = useDispatch();
  const issuer = useSelector((state) => state.issuer);

  const { data: user } = useCurrentUser();

  useEffect(() => {
    (async () => {
      const id = Object.keys(user?.roles?.issuer ?? {})?.[0];

      if (id) {
        try {
          const issuer = await getIssuerById(id);
          dispatch({ type: "issuer", payload: issuer });
        } catch (e) {}
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    identifyFullStory(user);
  }, [user]);

  const doLogout = async () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleOnIdle = (event) => {
    localStorage.clear();
    window.location = "/";
  };

  useIdleTimer({
    timeout: EIGHT_HOURS,
    crossTab: true,
    debounce: 500,
    onIdle: handleOnIdle,
  });

  return (
    <>
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link to="/offering" className="navbar-brand">
            <div className="text-center">
              <img src="/img/logo.svg" alt="logo" className="img-fluid" />
            </div>
          </Link>

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav">
              {isAdmin(user) && (
                <li className="nav-item">
                  <Link to="/brokers" className="nav-link">
                    <i className="fe fe-users" />
                    Brokers
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link to="/offering" className="nav-link">
                  <i className="fe fe-home" /> Offerings
                </Link>
              </li>
              {(isAdmin(user) || isAnyIssuerOwner(user)) && (
                <li className="nav-item">
                  <Link to="/accreditation" className="nav-link">
                    <i className="fe fe-file-text" /> Accreditation
                  </Link>
                </li>
              )}
              {/* {(isAdmin(user) || isAnyIssuerOwner(user)) && (
                <li className="nav-item">
                  <Link to="/invoices" className="nav-link">
                    <i className="fe fe-file-text" /> Invoices
                  </Link>
                </li>
              )} */}
              {(isAdmin(user) || isAnyIssuerOwner(user)) && (
                <li className="nav-item">
                  <Link to="/issuer" className="nav-link">
                    <i className="fe fe-dollar-sign" />
                    Issuers
                  </Link>
                </li>
              )}
            </ul>

            <Switch>
              <Route path="/offering/:id/**" component={OfferingMenu} />
              <Route path="/issuer/:id/**" component={IssuerMenu} />
            </Switch>

            <hr className="navbar-divider my-3" />

            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  <i className="fe fe fe-user" /> My Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={() => doLogout()}>
                  <i className="fe fe-log-out" /> Logout
                </Link>
              </li>
            </ul>

            <div className="navbar-user d-none">
              <div className="dropup">
                <a
                  href="#!"
                  className="avatar avatar-sm dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={"/img/default-user.png"}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {user && !isAdmin(user) && (
        <nav className="header mb-0 main-content">
          <div className="container-fluid">
            <div className="header-body py-1">
              <div className="row align-items-center">
                <div className="col"></div>
                <div className="col-auto d-flex align-items-center px-1">
                  <div className="fw-bold">
                    Hi {user?.first_name} {user?.last_name}!
                  </div>
                  <img
                    src={
                      issuer?.photo_url ? issuer?.photo_url : "/img/company.png"
                    }
                    alt="logo"
                    className="issuer-logo-height px-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Menu;
